import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getAllQrCodeThunk } from "../../Store/Slices/AllQrCodes"
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

export const AllQr = () => {
    let dispatch = useDispatch()
    let QrData = useSelector(e => e.allQrCode)

    useEffect(() => {
        if(QrData.allQrCodes.length==0){
            dispatch(getAllQrCodeThunk())
        }
    }, [])
    return (
        <div>
            {!QrData.error && !QrData.loading ?
                <div>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        <b>S No.</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>FirstName</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Qr Name</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Father Name</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Age</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Mobile No.</b>
                                    </TableCell>
                                    <TableCell>
                                        <b>Qr Img</b>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {QrData.allQrCodes.map((res,key) => (
                                    <TableRow key={key}>
                                            <TableCell>{key+1}</TableCell>
                                            <TableCell>{res.firstName!==null?res.firstName:"--"}</TableCell>
                                            <TableCell>{res.qrname!==null?res.qrname:'--'}</TableCell>
                                            <TableCell>
                                                {res.father_name!==null?res.father_name:"--"}
                                            </TableCell>
                                            <TableCell>
                                                {res.age!==null?res.age:"--"}
                                            </TableCell>
                                            <TableCell>
                                                {res.mobile_number!==null?res.mobile_number:"--"}
                                            </TableCell>
                                            <TableCell>
                                            <img style={{width:'50px'}} src={res.qrcode_image} alt=""/>
                                            </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
                : null
            }

        </div>
    )
}