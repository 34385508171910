import axios from "axios"
import { baseUrl } from "../auth/api"

export const CreateQrCode = async (data) => {
    try {
        return await axios.post(baseUrl + "qrCodeDetail/addQrDetails", data)
    }
    catch (err) {
        return err
    }
}

export const getAllQrCodeData = async () => {

    try {
        return await axios.get(baseUrl + "qrCodeDetail/getAllQrData")
    }
    catch (err) {
        return err
    }
}
export const getAllQrCodeDetail = async (id) => {
    try {
        return await axios(baseUrl + `qrCodeDetail/printData/${id}`)
    }
    catch (err) {
        return err
    }
}
export const getSingleQrDetail = async (id) => {
    try {
        return await axios(baseUrl + `qrCode/getQrcodeById/${id}`)
    }
    catch (err) {
        return err
    }
}

