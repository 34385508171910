import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import {
  Backdrop,
  Box,
  Button,
  Dialog,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { getCollectionData } from "../../Firebase/CloudFirestore/GetData";

export default function UsersListing() {
  const [allUsers, setAllUsers] = useState([]);

  

    const getEvent=async()=>{

      let data=await getCollectionData("users");
      setAllUsers(data)
      // console.log(data)
    }
useEffect(()=>{
  getEvent()
},[])



  return (
    <>
    
    
      
      
  
      <div class="container-fluid">
        <div class="col mt-2 d-flex justify-content-between">
          <h1 class="d-inline-block">Users</h1>
        </div>
      </div>
      <Box className="pb-3 d-flex justify-content-between mx-3">
        <Grid container>
          <Grid item md={6} xs={12}>
            <input
              className="w-75 form-control"
              type="search"
              placeholder="Search"
             
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mx: 2 }} className="border">
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell className="w-12"><b>S.No</b></TableCell> */}
              <TableCell className="w-20">
                <b>Account Name</b>
              </TableCell>
              <TableCell>
                <b>Email</b>
              </TableCell>
              <TableCell>
                <b>Child Name</b>
              </TableCell>
           
              <TableCell>
                <b>Born Year</b>
              </TableCell>
             
              <TableCell>
                <b>City</b>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allUsers.map((res, index) => {
              return (
                <TableRow key={index}>
                  {/* <TableCell>{index + 1}</TableCell> */}
                  <TableCell className="text_cap">{res.userName}</TableCell>
                  <TableCell>{res.userEmail}</TableCell>
                  <TableCell>{res.childName}</TableCell>
                  <TableCell>{res.childBorn}</TableCell>
                  <TableCell>{res.child_city}</TableCell>
              
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </>
  );
}
