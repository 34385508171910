import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { getSingleQrDetail } from "../../Services/QrCode";
import { useDispatch } from "react-redux";
import { startLoader, stopLoader } from "../../Store/Slices/LoaderSlice";
import style from "./style.module.css";
import { Apple, Shop } from "@mui/icons-material";

export const ShowQrCodeDetails = () => {
  let { id } = useParams();
  let [qrData, setQrData] = useState({});

  let dispatch = useDispatch();
  async function getQrData() {
    dispatch(startLoader());
    try {
      let res = await getSingleQrDetail(id);
      dispatch(stopLoader());
      console.log(res.data);
      setQrData(res.data[0]);
    } catch (err) {
      dispatch(stopLoader());
      console.log(err);
    }
  }

  useEffect(() => {
    getQrData();
    navigator.geolocation.getCurrentPosition((position) => {
      // alert(position.coords.latitude, position.coords.longitude)
      //  const url = `https://www.google.com/maps?q=${position.coords.latitude},${position.coords.longitude}`;
      //   window.open(url, '_blank');
    });
  }, []);

  return (
    <div className={style.showQrCode_con}>
      <div className="p-4">
        <img src="/images/Group.png" style={{ width: "100px" }} alt="" />
      </div>
      <div className="rounded-3 mx-4 my-2 border shadow px-3 pb-3">
        <div className="text-center text-primary">
          <small>Download Guardian QR App for better View</small>
        </div>
        <div className="d-flex gap-4 ">
          <span className={`d-flex align-items-center ${style.resource_card}`}>
            <div>
              <Shop className="fs-1 p-0" />
            </div>
            <div className="ms-2">
              <small>GET IT ON</small>
              <div className={`${style.large}`}>Google Play</div>
            </div>
          </span>
          <span className={`d-flex align-items-center ${style.resource_card}`}>
            <div>
              <Apple className="fs-1" />
            </div>
            <div className="ms-2">
              <small>GET IT ON</small>
              <div className={`${style.large}`}>Google Play</div>
            </div>
          </span>
          <span className="text-decoration-underline">Ignore</span>
        </div>
      </div>

      <div className={`text-center ${style.child_image_con}`}>
        <img src="/images/image 4.png" alt="" />
      </div>

      {qrData.father_name !== undefined ? (
        <div
          className={`fs-4 p-4 border shadow rounded-4 border-4 ${style.detail_con}`}
        >
          <div className="row m-0">
            <div className="col-6">
              <b>Name :-</b>
            </div>
            <div className="col-6 ">: &nbsp;{qrData.firstName} </div>
          </div>
          <div className="row m-0">
            <div className="col-6">
              <b>Father's Name :-</b>
            </div>
            <div className="col-6">: &nbsp;{qrData.father_name}</div>
          </div>
          <div className="row m-0">
            <div className="col-6">
              <b>Blood Group :-</b>
            </div>
            <div className="col-6">: &nbsp;{qrData.blood_group}</div>
          </div>
          <div className="row m-0">
            <div className="col-6">
              <b>Mobile No. :-</b>
            </div>
            <div className="col-6">: &nbsp;{qrData.mobile_number}</div>
          </div>
        </div>
      ) : null}
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};
