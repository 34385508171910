import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBV3k_RylDwY_3IEAlemvDk6YCPfuuIgss",
  authDomain: "verbal-autism-c8d22.firebaseapp.com",
  projectId: "verbal-autism-c8d22",
  storageBucket: "verbal-autism-c8d22.appspot.com",
  messagingSenderId: "573036642817",
  appId: "1:573036642817:web:d1ac6247391e620dfbf600",
  measurementId: "G-F3HQTW26MK"
};

const app = initializeApp(firebaseConfig);

export const storage = getStorage(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
