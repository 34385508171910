import React, { useState } from "react";
import "./Sidebar.css";
import { Menu } from "@mui/icons-material";
import { Backdrop, Box, useMediaQuery } from "@mui/material";
import { Link, NavLink, useNavigate } from "react-router-dom";
import LogoutIcon from "@mui/icons-material/Logout";
import { Triangle } from "react-loader-spinner";

export const Sidebar = ({ children }) => {
  const [toggleSidebar, setToggleSidebar] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [containerToggel, setContainerToggel] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const [loader, setLoader] = useState(false);

  const sidebarData = [
    { text: "All Products", path: "/product" },
    { text: "Segment", path: "/segment" },
    { text: "Brand", path: "/brand" },
    { text: "Model", path: "/models" },
    { text: "Category", path: "/category" },
    { text: "Manufacturer", path: "/manufacture" },
  ];

  const containerData = [
    { text: "About Us", path: "/about-us" },
    { text: "Privacy Policy", path: "/privacy-policy" },
    { text: "Terms & Condition", path: "/term-condition" },
    // { text: "Onboarding", path: "/on-boarding" },
  ];

  let navigate = useNavigate();
  console.log("ll");
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loader}
      >
        <Box>
          <Triangle
            height="80"
            width="80"
            color="black"
            ariaLabel="triangle-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={loader}
          />
        </Box>
      </Backdrop>

      <div className="d-flex" style={{ height: "100%", width: "100%" }}>
        <div
          className="sidebar_main_con"
          style={{
            width: matches
              ? toggleSidebar
                ? "270px"
                : "0px"
              : !toggleSidebar
                ? "270px"
                : "0px",
            overflow: 'hidden'
          }}
        >
          <div
            className="align-center px-4 py-2"
            onClick={() => {
              navigate("/");
            }}
            style={{ cursor: "pointer" }}
          >
            <img src="images/Group.png" className="w-100" alt="" />
            {/* Kapoor Enterprises */}
          </div>
          <div className="sidebar_item_con px-3">
           
            <NavLink to={"/"} className={"navLink"}>
              <div className="fs-5">
                Users
                <hr className="mb-3 mt-1" />
              </div>
            </NavLink>

            {/* <NavLink to={"/qrcode"} className={"navLink"}>
              <div className="fs-5">
                QR Code <hr className="mb-3 mt-1" />
              </div>
            </NavLink>

            <NavLink to={"/allQrData"} className={"navLink"}>
              <div className="fs-5">
                All QR <hr className="mb-3 mt-1" />
              </div>
            </NavLink> */}

          </div>
        </div>
        <div className="w-100">
          <div className="top_bar d-flex align-items-center justify-content-between">
            <span style={{ cursor: "pointer" }}>
              <Menu sx={{ color: "#003FB5" }}
                className="fs-1 ms-3"
                onClick={() => setToggleSidebar(!toggleSidebar)}
              />{" "}
              <i></i>
            </span>
            <Link
              style={{ textDecoration: "none" }}
              to={"/login"}
              onClick={() => {
                localStorage.removeItem("isLoggedIn");
              }}
            >
              <span
                style={{ color: "black" }}
                onClick={() => {
                  localStorage.setItem("isLoggedIn", "false");
                }}
              >
                <h6 className="d-flex align-items-center pe-3 pt-2">
                  <LogoutIcon sx={{ color: "#003FB5" }} />{" "}
                  <span> &nbsp;Logout</span>
                </h6>
              </span>
            </Link>
          </div>
          <div className="child_con p-4">{children}</div>
        </div>
      </div>
    </>
  );
};
