import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Backdrop } from "@mui/material";
import "./Qrcode.css";
import { Dialog } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import { Triangle } from "react-loader-spinner";
import { CreateQrCode, getAllQrCodeData } from "../../Services/QrCode";
import { QrCodeDetails } from "./QrcodeDetails";
import { useDispatch } from "react-redux";
import { clearAllQrData } from "../../Store/Slices/AllQrCodes";

export const Qrcode = () => {
  let [open, setOpen] = React.useState(false)
  let [loader, setLoader] = React.useState(true)
  let [allQrData, setAllQrData] = React.useState([])
  let dispatch = useDispatch()

  let [qrData, setQrData] = React.useState({
    qr_purpose: "",
    product_count: null
  })

  function inpChange(e) {
    let { name, value } = e.target
    setQrData({ ...qrData, [name]: value })
  }

  async function addQr(e) {
    e.preventDefault()

    let qrCodeIds = []
    for (let i = 0; i < qrData.product_count; i++) {
      const uu = uuidv4();
      const finalId = uu.split("-").join("");
      qrCodeIds.push(finalId)
    }
    let data = {
      ...qrData,
      qrCodeIds
    }
    setOpen(false)
    setLoader(true)
    try {
      let res = await CreateQrCode(data)
      dispatch(clearAllQrData())
      console.log(res)
      getAllQrData()
    }
    catch (err) {
      console.log(err)
      setLoader(false)
    }
  }

  async function getAllQrData() {
    try {
      let res = await getAllQrCodeData()
      console.log(res.data)
      setAllQrData(res.data)
      setLoader(false)
    }
    catch (err) {
      console.log(err)
      setLoader(false)
    }
  }
  React.useEffect(() => {
    getAllQrData()
  }, [])

  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-6">
            <input className="form-control" placeholder="Search" />
          </div>
          <div className="col-md-6 main-container">
            <Button
              className="btn_primary mt-2 mb-3"

              onClick={() => setOpen(true)}
            >
              ADD QR
            </Button>
          </div>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loader}
        >
          <Box>
            <Triangle
              height="80"
              width="80"
              color="black"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={loader}
            />
          </Box>
        </Backdrop>
        <Dialog
          open={open}
          maxWidth={"xs"}
          fullWidth
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className="p-4">
            <form onSubmit={addQr}>
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="denomination">Purpose</label>
                  <input
                    type="text"
                    size={40}
                    className="form-control"
                    value={qrData.qr_purpose}
                    onChange={inpChange}
                    name="qr_purpose"
                    required
                  />
                  <br />
                  <label className="">Count</label>
                  <input
                    type="number"
                    className="form-control"
                    name="product_count"
                    value={qrData.product_count}
                    onChange={inpChange}
                    required
                    max={10}
                  />
                  <div className="create mt-4 h justify-content-end">
                    <Button
                      className="btn_primary mt-3"
                      variant="contained"
                      type="submit"
                    >
                      Save
                    </Button>
                    <Button
                      className="btn cancel_btn ms-4 mt-3"
                      variant=""
                      type='button'
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Dialog>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <b>Purpose</b>
                </TableCell>
                <TableCell>
                  <b>Count</b>
                </TableCell>
                <TableCell>
                  <b>Created Date</b>
                </TableCell>
                <TableCell>
                  <b>Action</b>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allQrData && allQrData.map((res,key) => (
                <TableRow key={key}>
                  <>
                    <TableCell>{res.qr_purpose}</TableCell>
                    <TableCell>{res.product_count}</TableCell>
                    <TableCell>
                      {res.created_at.split("T")[0]}
                    </TableCell>
                    <TableCell>
                      {res.id}
                      <QrCodeDetails id={res.qr_id} />
                    </TableCell>
                  </>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
