import { Box, Button, CircularProgress, Dialog } from "@mui/material"
import { useEffect, useState } from "react"
import { getAllQrCodeDetail } from "../../Services/QrCode"
import QRGenerator from "./QRGenerator"


export const QrCodeDetails = ({ id }) => {
    let [open, setOpen] = useState(false)
    let [allData, setAllData] = useState([])
    let [loader, setLoader] = useState(true)


    async function getQrDetails() {
        try {
            let res = await getAllQrCodeDetail(id)
            setAllData(res.data)
            setLoader(false)
        }
        catch (err) {
            console.log(err)
            setLoader(false)
        }
    }


    function showDialog() {
        getQrDetails()
        setOpen(true)
    }

    function print() {
        QRGenerator(allData.map(e => e.qrcode_image))
    }

    return (
        <>
            <Button className="btn_primary" onClick={showDialog}>Show Image</Button>
            <Dialog
                open={open}
                maxWidth={"lg"}
                fullWidth
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box p={4}>
                    <h2>All Qrs</h2>
                    {loader ? <div className="ps-2">
                        <CircularProgress/>
                    </div> :
                        <>
                            <div className="d-flex flex-wrap gap-4">
                                {allData.map((res) => {
                                    return (
                                        <img src={res.qrcode_image} alt="" />
                                    )
                                })}
                            </div>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button className="cancel_btn" sx={{ mr: 3 }} onClick={() => setOpen(false)}>Cancel</Button>
                                <Button className="btn_primary" onClick={print}>Print</Button>
                            </Box>
                        </>
                    }

                </Box>
            </Dialog>
        </>
    )
}